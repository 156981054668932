<template>

<section class="section container columns is-centered  pt-4 ">
    <div class="column  is-half   is-mobile ">



        <div class="field  columns is-mobile mb-0">
            <div class="column">
                <label class="label">Habilitado <span style="color: red">*</span></label>
            </div>
            <div class="control column">
                <label class="checkbox-label">
                    <input v-if="!habilitado" type="checkbox" @click="habilitar">
                    <input v-else type="checkbox" checked @click="habilitar">
                    <span class="mt-2 checkbox-custom"></span>
                </label>
            </div>
        </div>
        <div class="columns mb-0">
            <div class="column is-2"></div>
            <div class="column">
                <p v-show="message_error.habilitado_error != ''" class="help is-danger ml-4">{{message_error.habilitado_error}}</p>
            </div>

        </div>




        <!-- ==================== -->

        <div class="field columns ">
            <div class="column   is-one-fifth ">
                <label class="label pt-2">Api Key <span style="color: red">*</span></label>

            </div>
            <div class="control column is-two-thirds ">
                <input v-model="api_key" name="api_key" class="input ml-0 mr-2" type="text" >
                <p v-show="message_error.api_key_error != ''" class="help is-danger ">{{message_error.api_key_error}}</p>
            </div>
        </div>

        <!-- ==================== -->

        <div class="field  columns ">
            <div class="column  is-one-fifth ">
                <label class="label ">Centrado</label>
            </div>
            <div class="columns has-text-centered ml-0 mr-0">
                <div class="control column  ">
                    <input class="input input2 mr-0" type="text" v-model="centrado1" >
                    <input class="input input2 ml-1" type="text" v-model="centrado2">
                     <p  class="help is-grey">Ejemplo: -27.58666 | -58.666</p>
                </div>


            </div>
            <div v-show="!isMobile"  class="columns   ">

                        <div  class="column pl-1  mr-3">
                                <a class="button" href="https://www.google.com.ar/maps/place/27%C2%B026'03.6%22S+58%C2%B057'28.6%22W/@-27.442618,-59.0488903,11z/data=!4m6!3m5!1s0x0:0x0!7e2!8m2!3d-27.4343344!4d-58.957942" target="_blank">
                                    <i class="fas fa-map-marked-alt"></i>
                                </a>
                        </div>
            </div>

             <div v-show="isMobile"  class="columns   has-text-centered ">

                        <div  class="column ">
                                <a class="button" href="https://www.google.com.ar/maps/place/27%C2%B026'03.6%22S+58%C2%B057'28.6%22W/@-27.442618,-59.0488903,11z/data=!4m6!3m5!1s0x0:0x0!7e2!8m2!3d-27.4343344!4d-58.957942" target="_blank">
                                    <i class="fas fa-map-marked-alt"></i>
                                </a>
                        </div>
            </div>
        </div>


        <!-- ==================== -->

        <div class="field  columns  ">
            <div class="column   is-one-fifth has-text-center">
                <label class="label  pt-1">Zoom</label>
            </div>
            <div class="control column is-flex-grow-0  ">
                <input class="input" type="text" v-model="zoom">
            </div>
        </div>

            <!-- ==================== -->

        <div class="field is-mobile columns ">
            <div class="column ">
                <label class="label">Pantalla completa</label>
            </div>
            <div class="control column">
                <label class="checkbox-label">

                    <input v-if="!pantalla" type="checkbox" @click="habilitarPantalla">
                    <input v-else type="checkbox" checked @click="habilitarPantalla">

                    <span class="mt-2 checkbox-custom"></span>

                </label>
            </div>
        </div>

            <!-- ==================== -->

        <div class="field is-mobile columns ">
            <div class="column  ">
                <label class="label">Vista de calle</label>
            </div>
            <div class="control column ">
                <label class="checkbox-label">

                    <input v-if="!vista" type="checkbox" @click="habilitarVistaCalle">
                    <input v-else type="checkbox" checked @click="habilitarVistaCalle">

                    <span class="mt-2 checkbox-custom"></span>

                </label>
            </div>
        </div>

            <!-- ==================== -->

        <div class="field is-mobile columns ">
            <div class="column">
                <label class="label">Tipo de mapa</label>
            </div>
            <div class="control column ">
                <label class="checkbox-label">

                    <input v-if="!tipo_mapa" type="checkbox" @click="habilitarMapa">
                    <input v-else type="checkbox" checked @click="habilitarMapa">

                    <span class="mt-2 checkbox-custom"></span>

                </label>
            </div>
        </div>

        <!-- ==================== -->

        <div class="field  columns is-mobile">
            <div class="column">
                <label class="label">Escala</label>
            </div>
            <div class="control column">
                <label class="checkbox-label">

                    <input v-if="!escala" type="checkbox" @click="habilitarEscala">
                    <input v-else type="checkbox" checked @click="habilitarEscala">

                    <span class="mt-2 checkbox-custom"></span>

                </label>
            </div>
        </div>

        <!-- ==================== -->

        <div class="field">
            <div v-show="!isMobile" class="control columns ">
                <div class="column   is-one-fifth  has-text-center">
                    <button class="button mr-2 has-text-center " style=" background-color: #DD2923 ; color:white;" @click="volver">
                        Cancelar
                    </button>
                </div>

                <div class="column  is-flex-grow-1 ">
                    <button class="button  is-success " style="width: 20.0rem;" @click="validar">
                            Guardar
                    </button>
                </div>
            </div>
             <div v-show="isMobile" class="control columns is-mobile">


                <div class="column     has-text-center">
                    <button class="button mr-2 has-text-center " style=" background-color: #DD2923 ; color:white;" @click="volver">
                        Cancelar
                    </button>
                </div>

                <div class="column   is-flex-grow-1 ">
                    <button class="button has-text-center is-success " style="width: 9.5rem;" @click="validar">
                            Guardar
                    </button>
                </div>



            </div>
        </div>

    </div>
</section>

</template>

<script>
import { useRouter } from 'vue-router';
import { GraphQLClient } from 'graphql-request'
import { ref } from '@vue/reactivity';
import store from '@/store'
import { inject } from '@vue/runtime-core';
export default {

    name:'ConfiguracionDeMapa',


    created () {
        this.traerMapa()
    },

    setup(){

        const isMobile = inject('isMobile')
        const api_key =  ref('')
        const habilitado = ref(false)
        const message_error = ref([
            {api_key_error: '', habilitado_error: ''}
        ])
        const error= ref(false)
        const zoom =  ref('')
        const pantalla = ref(false)
        const vista = ref(false)
        const escala = ref(false)
        const tipo = ref('mapa')
        const centrado1 = ref('')
        const centrado2 = ref('')
        const centrado = ref('')
        const editar = ref(false)
        const id = ref(null)
        const tipo_mapa = ref(false)
        const config = ref([])
        const endpoint = store.state.url_backend

        const router = useRouter()

        const validar = () => {
            error.value = false,
            message_error.value.api_key_error = ''
            message_error.value.habilitado_error = ''

            if (api_key.value == '') {
                error.value = true
                message_error.value.api_key_error = 'El campo api key no puede estar vacio'
            }
            if (habilitado.value == false) {
                error.value
                message_error.value.habilitado_error = 'Esta opcion tiene que estar tildada'
            }

            if (error.value) {
                // console.log('No se puede crear')
            } else {
                // console.log('Se creo con exito')
                if (!editar.value) {
                    configMapa()
                } else {
                    editarConfigMapa()
                }
            }
        }

        const habilitar = () => {
            habilitado.value = !habilitado.value
            // console.log(habilitado.value)
        }
        const habilitarPantalla = () => {
            pantalla.value = !pantalla.value
            // console.log(this.pantalla)
        }
        const habilitarVistaCalle = () => {
            vista.value = !vista.value
        }
        const habilitarMapa = () => {
            tipo_mapa.value = !tipo_mapa.value
        }
        const habilitarEscala = () => {
            escala.value = !escala.value
        }

        const  traerMapa = () => {
            // const endpoint = "http://45.70.8.41:88/graphql" // guardamos la url del playground
            const client = new GraphQLClient(endpoint) // creamos la consulta para usarlo luego
            client.rawRequest(/* GraphQL */ `
            query{
                configsistemasxtipo(tipo: "mapa"){
                    id
                    tipo
                    config
                }
            }`,
            {
                // variables
            },
            {
                authorization: `Bearer ${ localStorage.getItem('user_token') }`
            })
            .then(data => {
                // console.log(data)
                let mapa = data.data.configsistemasxtipo
                if (mapa.length == 0) {
                    editar.value = false
                } else {
                    editar.value = true
                    id.value = mapa[0].id
                    let config = JSON.parse(mapa[0].config)
                    // console.log(mapa[0])
                    zoom.value = config.zoom
                    // this.escala = Boolean(config.Escala)
                    if (config.Escala == 'true') {
                        escala.value = true
                    } else {
                        escala.value = false
                    }
                    api_key.value = config.Api_key
                    centrado.value = config.centrado.split(',')

                    centrado1.value = centrado.value[0]
                    centrado2.value = centrado.value[1]
                    if (config.habilitar == 'true') {
                        habilitado.value = true
                    } else {
                        habilitado.value = false
                    }

                    if (config.Tipo_de_mapa == 'true') {
                        tipo_mapa.value = true
                    } else {
                        tipo_mapa.value = false
                    }

                    if (config.vista_de_calle == 'true') {
                        vista.value = true
                    } else {
                        vista.value = false
                    }

                    if (config.Pantalla_completa == 'true') {
                        pantalla.value = true
                    } else {
                        pantalla.value = false
                    }
                }
                // console.log(editar.value)
            })
        }


        const configMapa = () => {

            centrado.value = centrado1.value + ','+ centrado2.value

            config.value = `{\"habilitar\":\"${habilitado.value}\", \"Api_key\":\"${api_key.value}\", \"centrado\":\"${centrado.value}\",\"zoom\":\"${zoom.value}\", \"Pantalla_completa\":\"${pantalla.value}\",\"vista_de_calle\":\"${vista.value}\", \"Tipo_de_mapa\":\"${tipo_mapa.value}\", \"Escala\":\"${escala.value}\"}`

            // const endpoint = "http://45.70.8.41:88/graphql" // guardamos la url del playground
            const client = new GraphQLClient(endpoint) // creamos la consulta para usarlo luego
            client.rawRequest(/* GraphQL */ `
            mutation($tipo:String!, $config:String!){
               creaConfigsistema(input: {
                        tipo: $tipo,
                        config: $config,

                    }){
                        id,
                        tipo,
                        config

                    }
            }`,
            {
                tipo: tipo.value,
                config: config.value,


            },
            {

                authorization: `Bearer ${ localStorage.getItem('user_token') }`

            })
            .then((data) => {
                router.push({name: 'Configuraciones'})

            }).catch(error => {
            //   console.log(error.response);

            })
        }

        const editarConfigMapa = () => {

            centrado.value = centrado1.value + ','+ centrado2.value

            config.value = `{\"habilitar\":\"${habilitado.value}\", \"Api_key\":\"${api_key.value}\", \"centrado\":\"${centrado.value}\",\"zoom\":\"${zoom.value}\", \"Pantalla_completa\":\"${pantalla.value}\",\"vista_de_calle\":\"${vista.value}\", \"Tipo_de_mapa\":\"${tipo_mapa.value}\", \"Escala\":\"${escala.value}\"}`

            // const endpoint = "http://45.70.8.41:88/graphql" // guardamos la url del playground
            const client = new GraphQLClient(endpoint) // creamos la consulta para usarlo luego
            client.rawRequest(/* GraphQL */ `
            mutation($id: ID!,$tipo:String!, $config:String!){
               modificaConfigsistema(id: $id, input: {
                        tipo: $tipo,
                        config: $config,

                    }){
                        id,
                        tipo,
                        config

                    }
            }`,
            {
                id: id.value,
                tipo: tipo.value,
                config: config.value,


            },
            {

                authorization: `Bearer ${ localStorage.getItem('user_token') }`

            })
            .then((data) => {
                // console.log(data)
                router.push({name: 'Configuraciones'})

            }).catch(error => {
            //   console.log(error.response);

            })
        }



        const volver = () => {
            router.go(-1)
        }
         return{
            isMobile ,
            api_key ,
            habilitado ,
            message_error ,
            error,
            zoom ,
            pantalla ,
            vista ,
            escala ,
            tipo,
            centrado1 ,
            centrado2 ,
            centrado ,
            editar ,
            id ,
            config,
            tipo_mapa,
            validar,
            habilitar,
            habilitarPantalla,
            habilitarVistaCalle,
            habilitarMapa,
            habilitarEscala ,
            traerMapa,
            configMapa ,
            editarConfigMapa ,

            volver
        }
    }
}
</script>

<style scoped>





.checkbox-label input{

    position: absolute;
    opacity: 0;
    cursor: pointer;

}

.checkbox-label  .checkbox-custom{

        position: absolute;
        top:0px;
        left: 0px;
        height: 24px;
        width: 24px;
        background-color: transparent;
        border-radius:5px;
        border: 2px solid grey;

}

.checkbox-label .checkbox-custom::after{


        position: absolute;
        content: "";
        top:12px;
        left: 12px;
        height: 0px;
        width: 0px;
        border-radius:5px;
        border:  solid rgb(47, 133, 214);
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(0deg) scale(0);
        -ms-transform: rotate(0deg) scale(0);
        transform: rotate(0deg) scale(0);
        opacity: 1;



}


.checkbox-label input:checked ~ .checkbox-custom{
        background-color: white;
        -webkit-transform: rotate(0deg) scale(1);
        -ms-transform: rotate(0deg) scale(1);
         transform: rotate(0deg) scale(1);
         opacity:1;

         border: solid #008575;
         border-radius: 5px;


}


.checkbox-label input:checked ~ .checkbox-custom::after{

        -webkit-transform: rotate(45deg) scale(1);
        -ms-transform: rotate(45deg) scale(1);
         transform: rotate(45deg) scale(1);
         opacity:1;
         left:8px;
         top: 3px;
         width: 6px;
         height: 12px;
         border: solid #008575;
         border-width: 0 2px 2px 0;
         background-color: transparent;
         border-radius: 0;


}



.input{

  width: 20.0rem;

}
.input2{

    width: 8.1rem;

}
.options{
        width: 17.1rem;
}

.select{

    width: 17.1rem;

}

</style>