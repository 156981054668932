<template>
    <div class="container">
        <div class="column">
            <div class="columns">
                <div v-show="!isMobile" class="columns   pt-3 ">
                    <div class="column  mr-0 pr-0 ">
                        <button class="button is-static has-text-black" >Mostrar</button>
                    </div>
                    <div class="column  pl-0 control" v-tooltip="'Filas'">
                        <div class="select " >
                            <select class="options" v-model="mostrar_cantidad">
                                <option value="50" >50</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                                <option value="500">500</option>
                            </select>
                        </div>
                    </div>
                 </div>
                <div v-show="isMobile" class="columns has-text-right is-mobile pt-3">
                    <div class="column  mr-0 pr-0 has-text-right">
                        <button class="button is-static has-text-black">Mostrar</button>
                    </div>
                    <div class="column  pl-0  has-text-left control" >
                        <div class="select ">
                            <select class="options" v-model="mostrar_cantidad">
                                <option value="50" >50</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                                <option value="500">500</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div v-show="!isMobile"  class="column  has-text-center">
                    <!-- Buscador -->
                    <div class=" field ">
                        <p class="control has-icons-left " style="width:20.8rem">
                            <input class="input " type="text" placeholder="Buscar ciudad" v-model="buscar">
                            <span class="icon is-left">
                                <i class="fas fa-search" ></i>
                            </span>
                        </p>
                    </div>
                </div>
                <div v-show="isMobile"  class="column  has-text-center ml-3">
                    <!-- Buscador -->
                    <div class=" field ">
                        <p class="control has-icons-left w-100">
                            <input class="input " type="text" placeholder="Buscar ciudad" v-model="buscar">
                            <span class="icon is-left">
                                <i class="fas fa-search" ></i>
                            </span>
                        </p>
                    </div>
                </div>
                <div class="column is-5 ">
                    <div class="buttons is-justify-content-flex-end">
                        <div class="dropdown pr-2" :class="{'is-active':mas_opciones}" >
                            <div id="mas-opciones" class="dropdown-trigger">
                                <button id="mas-opciones" class="button has-background-info has-text-white" aria-haspopup="true" aria-controls="dropdown-menu" @click="mas_opciones = !mas_opciones">
                                    <span id="mas-opciones">Mas opciones</span>
                                    <span id="mas-opciones" class="icon is-small">
                                        <i id="mas-opciones" class="fas fa-angle-down" aria-hidden="true"></i>
                                    </span>
                                </button>
                            </div>
                            <div class="dropdown-menu pt-0" id="dropdown-menu" role="menu">
                                <div class="dropdown-content">
                                    <a id="mas-opciones" class="dropdown-item" @click="eliminar_adv_masivo">
                                        Eliminación masiva
                                    </a>
                                </div>
                            </div>
                        </div>
                        <button class="button has-background-info has-text-white" @click="filtro">Filtro</button>

                        <div>
                            <div class="modal" :class="{'is-active': filtrar}">
                                <div id="modal-background" class="modal-background " style="background-color: rgb(197, 197, 197, 0.1)"></div>
                                <div class="modal-content-width">
                                    <div class="container has-text-centered has-background-white p-3" id="modal">

                                        <div class="columns mb-0">
                                            <div class="column is-9 pb-0" :class="{'ml-5':!isMobile}">
                                                <h1 class="is-size-3 has-text-black has-text-weight-bold pt-3" :class="{'ml-5':!isMobile}">Filtrar tabla</h1>
                                            </div>
                                            <div class="column" :class="{'mt-3':!isMobile}">
                                                <button class="button verde-puwic mb-0 has-text-white has-text-weight-bold" @click="limpiarFiltro">Limpiar</button>
                                            </div>
                                        </div>


                                        <div :class="{'modal-scrolleable' : isMobile}">
                                            <!-- Nombre -->
                                            <div class="field columns" :class="{'mb-0 pt-1' : isMobile, 'mt-2': !isMobile}">
                                                <div class="column is-one-fifth ml-4 pt-2">
                                                    <label class="label" :class="{'has-text-right': !isMobile}" >Nombre</label>
                                                </div>
                                                <div class="columns has-text-centered ml-0 mr-0">
                                                    <div class="control column is-flex-grow-0" :class="{'py-1' : isMobile}">
                                                        <div class="select" >
                                                            <select class="options" v-model="tipo_filtro.nombre">
                                                                <option value="contiene">contiene</option>
                                                                <option value="no contiene">no contiene</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="control column" :class="{'pt-1' : isMobile}">
                                                        <input class="input" v-model="datos_filtro.nombre" name="nombre" type="text">
                                                    </div>

                                                </div>
                                            </div>

                                            <!-- Codigo postal -->
                                            <div class="field columns mt-2 mb-0">
                                                <div class="column is-one-fifth ml-4 pt-0" style="min-width: 50px">
                                                    <label class="label" :class="{'has-text-right': !isMobile}">Codigo Postal</label>
                                                </div>
                                                <div class="columns has-text-centered ml-0 mr-0">
                                                    <div class="control column is-flex-grow-0" :class="{'py-1': isMobile}">
                                                        <div class="select" style="width: 139px">
                                                            <select class="options" v-model="tipo_filtro.codigopostal" style="width: 139px">
                                                                <option value="es">es</option>
                                                                <option value="mayor">mayor a</option>
                                                                <option value="menor">menor a</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="control column" :class="{'pt-1': isMobile}">
                                                        <input class="input" v-model="datos_filtro.codigopostal" name="codigopostal" type="text">
                                                    </div>

                                                </div>
                                            </div>

                                            <!-- Provincia -->
                                            <div class="field columns mt-0" :class="{'mb-0': isMobile}">
                                                <div class="column is-one-fifth ml-4 pt-2" style="min-width: 50px">
                                                    <label class="label" :class="{'has-text-right': !isMobile}">Provincia</label>
                                                </div>
                                                <div class="columns has-text-centered ml-0 mr-0">
                                                    <div class="control column is-flex-grow-0" :class="{'py-1': isMobile}">
                                                        <div class="select" >
                                                            <select class="options" v-model="tipo_filtro.provincia">
                                                                <option value="contiene">contiene</option>
                                                                <option value="no contiene">no contiene</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="control column" :class="{'pt-1': isMobile}">
                                                        <input class="input" v-model="datos_filtro.provincia" name="provincia" type="text">
                                                    </div>

                                                </div>
                                            </div>

                                            <!-- Pais -->
                                            <div class="field columns" :class="{'my-0': isMobile, 'mt-2': !isMobile}">
                                                <div class="column is-one-fifth ml-4 pt-2" style="min-width: 50px">
                                                    <label class="label" :class="{'has-text-right': !isMobile}">País</label>
                                                </div>
                                                <div class="columns has-text-centered ml-0 mr-0">
                                                    <div class="control column is-flex-grow-0" :class="{'py-1': isMobile}">
                                                        <div class="select" >
                                                            <select class="options" v-model="tipo_filtro.pais">
                                                                <option value="contiene">contiene</option>
                                                                <option value="no contiene">no contiene</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="control column" :class="{'pt-1': isMobile}">
                                                        <input class="input" v-model="datos_filtro.pais" name="pais" type="text">
                                                    </div>

                                                </div>
                                            </div>

                                            <!-- Botones -->
                                            <div class="columns">
                                                <div class="column">
                                                    <div class="buttons">
                                                        <button class="button has-background-danger is-outlined btn has-text-white has-text-weight-bold" style="width: 30%" @click="filtrar = false">Cancelar</button>
                                                        <button class="button verde-puwic is-outlined btn has-text-white has-text-weight-bold boton-tamaño" @click="filtro_avanzado">Filtro</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <router-link :to="{name: 'NuevaCiudad'}" class="button has-background-link has-text-white">Nueva ciudad</router-link> -->
                        <a @click="verificarHabilitado('NuevaCiudad')" class="button has-background-link has-text-white">Nueva Ciudad</a>
                    </div>
                </div>
            </div>
        </div>

        <button class="total-element">Total: {{Allciudades.length}}</button>
        <button v-show="filtrado" class="total-element mx-0 pointer" @click="filtro">Total filtrado: {{totalElement}}</button>

        <div class="column table-container">
            <table class="table has-background-white-ter " style="width: 100%" >
                <thead>
                    <tr>
                    <th>
                       <div v-show="todo">
                            <button class="button is-small is-light"  @click="marcar_todo">
                                    <span class="icon-text">
                                        <span class="icon is-size-6">
                                            <i class="far fa-check-square"></i>
                                        </span>
                                    </span>
                            </button>
                        </div>
                        <div v-show="!todo">
                            <button class="button is-small is-light"  @click="marcar_todo">
                                <span class="icon-text">
                                    <span class="icon is-size-6">
                                        <i class="far fa-square"></i>
                                    </span>
                                </span>
                            </button>
                        </div>
                    </th>
                    <th>
                        <button @click="ordenar('nombre')" class="button  has-text-weight-medium has-text-black is-light px-0 py-0 ">
                            Nombre
                            <div  v-show="clics.n1!=false">
                                <span  v-show="ordenarCiudad!=false">
                                    <i class="fas fa-arrow-up"></i>
                                </span>
                                <span v-show="ordenarCiudad==false">
                                    <i class="fas fa-arrow-down"></i>
                                </span>
                            </div>
                        </button>
                    </th>
                    <th>
                        <button @click="ordenar('codigo_postal')" class="button  has-text-weight-medium has-text-black is-light px-0 py-0">
                            Codigo Postal
                            <div  v-show="clics.n2!=false">
                                <span  v-show="ordenarCodigo!=false">
                                    <i class="fas fa-arrow-up"></i>
                                </span>
                                <span v-show="ordenarCodigo==false">
                                    <i class="fas fa-arrow-down"></i>
                                </span>
                            </div>
                        </button>
                    </th>
                    <th>
                        <button @click="ordenar('provincia')" class="button  has-text-weight-medium has-text-black is-light px-0 py-0">
                            Provincia
                            <div  v-show="clics.n3!=false">
                                <span  v-show="ordenarProvincia!=false">
                                    <i class="fas fa-arrow-up"></i>
                                </span>
                                <span v-show="ordenarProvincia==false">
                                    <i class="fas fa-arrow-down"></i>
                                </span>
                            </div>
                        </button>
                    </th>
                    <th>
                        <button @click="ordenar('pais')" class="button  has-text-weight-medium has-text-black is-light px-0 py-0">
                            País
                            <div  v-show="clics.n4!=false">
                                <span  v-show="ordenarPais!=false">
                                    <i class="fas fa-arrow-up"></i>
                                </span>
                                <span v-show="ordenarPais==false">
                                    <i class="fas fa-arrow-down"></i>
                                </span>
                            </div>
                        </button>
                    </th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(ciudad,index) in ciudades" :index="index" :key="ciudad.id" :class="{'fondo-fila':index%2==0 && !ciudad.tildado,'seleccionado': ciudad.tildado, 'deseleccionado': !ciudad.tildado}">

                        <td>
                            <input v-if="!ciudad.tildado" @click="tildar(ciudad)" class="ml-2"  type="checkbox">
                            <input v-else @click="tildar(ciudad)"  class="ml-2" checked type="checkbox">
                        </td>
                        <td @click="modal(ciudad)">{{ciudad.nombre}}</td>
                        <td @click="modal(ciudad)">{{ciudad.codigo_postal}}</td>
                        <td @click="modal(ciudad)">{{ciudad.provincia}}</td>
                        <td @click="modal(ciudad)">{{ciudad.pais}}</td>

                        <div>
                            <div class="modal" :class="{'is-active': ciudad.activo}" @click="ciudad.activo = false">
                                <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.1)"></div>
                                <div class="modal-content-width" style="border:1px ridge grey;">
                                    <div class="container has-text-centered has-background-white" style="width: 200px" id="modal">
                                        <div class="column p-0 py-1 pt-4 px-4">
                                            <!-- <router-link :to="{name: 'EditarCiudad', params: {id: ciudad.id}}" class="button verde-puwic is-outlined btn has-text-white has-text-weight-bold" style="width: 100%">Editar</router-link> -->
                                            <a @click="verificarHabilitadoParam('EditarCiudad', ciudad.id)" class="button verde-puwic is-outlined btn has-text-white has-text-weight-bold" style="width: 100%">Editar</a>
                                        </div>
                                        <div class="column p-0 pb-4 px-4">
                                            <button class="button has-background-danger is-outlined btn has-text-white has-text-weight-bold" style="width: 100%" @click="eliminar_adv(ciudad)">Eliminar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="modal" :class="{'is-active': mostrar_advertencia}" >
                                <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.0)"></div>
                                <div class="modal-content-width" style="border:1px ridge grey;">
                                    <div class="container has-text-centered has-background-white px-1 py-1" style="width: 320px" id="modal">
                                        <h1 class="is-size-3 has-text-weight-semibold has-text-danger" :class="{'is-size-4':isMobile}">¿Estas seguro?</h1>

                                        <p v-show="opsMasivo==false" class="has-text-centered ">Quiere eliminar la ciudad {{ciudadelegida.nombre}}. Esta acción no se podra revertir</p>
                                        <p v-show="opsMasivo==true" class="has-text-centered ">Se van eliminar  las ciudades seleccionadas. Esta accion no se puede revertir.</p>
                                        <div class="columns   py-1 pt-4 px-1">
                                            <div class="column ">
                                                <button class="button w-100 has-background-danger is-outline btn has-text-white has-text-weight-blod" @click="cancelar">Cancelar</button>
                                            </div>
                                            <div class="column">
                                                <button class="button w-100 verde-puwic is-outline btn has-text-white has-text-weight-blod" @click="confirmar">Si,eliminar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Mensaje de que hay relacion con algun anunciante o punto wifi -->
                        <div>
                            <div class="modal" :class="{'is-active': mostrar_relacion.ver}">
                                <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.0)"></div>
                                <div class="modal-content-width has-text-black" style="border:1px ridge grey;" :class="{'modal-puntowifi-escritorio' : !isMobile, 'modal-puntowifi-mobil' : isMobile}">
                                    <div class="container has-text-centered has-background-white" :class="{'p-2':isMobile, 'p-5':!isMobile}" id="modal">
                                        <!-- <h1 class="is-size-3 has-text-weight-semibold" :class="{'is-size-4':isMobile}">No se puede borrar el anuncio</h1> -->
                                        <p class="has-text-centered has-text-danger">{{mostrar_relacion.mensaje}}</p>
                                        <div class="columns mt-2">
                                            <div class="column">
                                                <button class="button w-100 verde-puwic is-outline btn has-text-white has-text-weight-blod" @click="mostrar_relacion.ver = false">Esta bien</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Validacion de si selecciono algo -->
                        <div>
                            <div class="modal" :class="{'is-active': mostrar_validacion}">
                                <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.0)"></div>
                                <div class="modal-content-width has-text-black" style="border:1px ridge grey;" :class="{'modal-puntowifi-escritorio' : !isMobile, 'modal-puntowifi-mobil' : isMobile}">
                                    <div class="container has-text-centered has-background-white" :class="{'p-2':isMobile, 'p-5':!isMobile}" id="modal">
                                        <!-- <h1 class="is-size-3 has-text-weight-semibold" :class="{'is-size-4':isMobile}">No se puede borrar el anuncio</h1> -->
                                        <p class="has-text-centered has-text-danger">Se debe seleccionar alguna ciudad para realizar la accion</p>
                                        <div class="columns mt-2">
                                            <div class="column">
                                                <button class="button w-100 verde-puwic is-outline btn has-text-white has-text-weight-blod" @click="mostrar_validacion = false">Esta bien</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Mensaje de que se cargo con exito -->
                        <div>
                            <div class="modal" :class="{'is-active': carga_exitosa}">
                                <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.0)"></div>
                                <div class="modal-content-width has-text-black" style="border:1px ridge grey;" :class="{'modal-puntowifi-escritorio' : !isMobile, 'modal-puntowifi-mobil' : isMobile}">
                                    <div class="container has-text-centered has-background-white" :class="{'p-2':isMobile, 'p-5':!isMobile}" id="modal">
                                        <!-- <h1 class="is-size-3 has-text-weight-semibold" :class="{'is-size-4':isMobile}">No se puede borrar el anuncio</h1> -->
                                        <p v-show="comprobar==true" class="has-text-centered has-text-success">Se cargo con exito la ciudad.</p>
                                        <p v-show="comprobar_edi==true" class="has-text-centered has-text-success">Se edito con exito la ciudad.</p>
                                        <div class="columns mt-2">
                                            <div class="column">
                                                <button class="button w-100 verde-puwic is-outline btn has-text-white has-text-weight-blod" @click="carga_exitosa = false">Esta bien</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="modal" :class="{'is-active': accion_exitosa}">
                                <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.0)"></div>
                                <div class="modal-content-width has-text-black" style="border:1px ridge grey;" :class="{'modal-puntowifi-escritorio' : !isMobile, 'modal-puntowifi-mobil' : isMobile}">
                                    <div class="container has-text-centered has-background-white" :class="{'p-2':isMobile, 'p-5':!isMobile}" id="modal">
                                        <!-- <h1 class="is-size-3 has-text-weight-semibold" :class="{'is-size-4':isMobile}">No se puede borrar el anuncio</h1> -->
                                        <p v-show="paso_elim==true" class="has-text-centered has-text-success">Se eliminaron las ciudades  exitosamente.</p>
                                        <p v-show="paso_elim==false" class="has-text-centered has-text-danger">No se logro eliminar las ciudades seleccionadas.</p>
                                        <div class="columns mt-2">
                                            <div class="column">
                                                <button class="button w-100 verde-puwic is-outline btn has-text-white has-text-weight-blod" @click="accion_exitosa = false">Esta bien</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </tr>
                </tbody>
            </table>

            <div v-show="preload" class="loader-wrapper is-active-loading">
                <div class="loader is-loading "></div>
            </div>

            <div v-if="!preload && ciudades.length == 0">
                <h1 class="has-text-centered is-size-4 has-text-grey">No hay ciudades</h1>
            </div>
        </div>
        <nav v-show="totalElement > mostrar_cantidad" class="pagination is-right" role="navigation" aria-label="pagination">
            <button v-if="paginaInfo.page > 1" class="pagination-previous" @click="prevPage">Anterior</button>
            <button v-else class="pagination-previous" disabled >Anterior</button>
            <button v-if="paginaInfo.page < pageCont" class="pagination-next" @click="nextPage">Siguiente</button>
            <button v-else class="pagination-next" disabled>Next</button>
            <ul class="pagination-list" style="justify-content: center">
                <li v-show="paginaInfo.prev > 1"> <button class="pagination-link" @click="inicio">1</button></li>
                <li v-show="paginaInfo.prev > 2"><span class="pagination-ellipsis">&hellip;</span></li>
                <li v-show="paginaInfo.prev != null"><button class="pagination-link" @click="prevPage">{{paginaInfo.prev}}</button></li>
                <li><button class="pagination-link is-current">{{paginaInfo.page}}</button></li>
                <li v-show="paginaInfo.next != null && paginaInfo.next > 1"><button class="pagination-link" @click="nextPage">{{paginaInfo.next}}</button></li>
                <li v-show="paginaInfo.next < pageCont - 1 && paginaInfo.next != null"><span class="pagination-ellipsis">&hellip;</span></li>
                <li v-show="paginaInfo.next != null && (paginaInfo.next < pageCont) && (pageCont != 1)" ><button class="pagination-link" @click="final">{{pageCont}}</button></li>
            </ul>
        </nav>
    </div>

</template>

<script>

import {filtrar_campo_numeral} from '@/composables/filtrar_campo_numeral.js'
import {filtrar_campo_texto} from '@/composables/filtrar_campo_texto.js'
import store from '@/store';
import { GraphQLClient, gql } from 'graphql-request'
import {computed, inject, ref, watch, watchEffect} from 'vue'
import { useRoute, useRouter } from "vue-router";
import {queryMe} from '@/composables/queryMe.js'



export default {

    created(){
        this.traerCiudades()
        this.ciudades_aux = this.ciudades
        this.comprobar_carga()
        this.comprobar_edicion()


    },

    setup(){

        const todo = ref(false)
        const mostrar_cantidad = ref(50)
        const isMobile = inject('isMobile')
        const eliminarCiudades = ref([])
        const ciudades = ref([])
        const ciudades_aux = ref([])
        const datos_filtro = ref({nombre: '', codigopostal: '', provincia: '', pais: ''})
        const tipo_filtro = ref({nombre: 'contiene', codigopostal: 'es', provincia: 'contiene', pais: 'contiene'})
        const filtrar =  ref(false)
        const buscar = ref('')
        const info = ref({count: 0, pages:0, next:0, prev: 0, page: 0, total: 0})
        const route = useRoute()
        const router = useRouter()
        const endpoint = store.state.url_backend
        const ordenarCiudad = ref(false)
        const ordenarCodigo = ref(false)
        const ordenarProvincia = ref(false)
        const ordenarPais = ref(false)
        const clics = ref({n1:false,n2:false,n3:false, n4:false})
        const mas_opciones = ref(false)
        const Allciudades = ref([])
        const paginaInfo = ref({page: 1, pageSize: parseInt(mostrar_cantidad.value), prev: null, next: 0})
        const pageCont = ref(0) // capaz tenga que agregarlo en la consulta de datos
        const totalElement = ref(0)
        const ciudadelegida = ref([{id:null,nombre:''}])
        const mostrar_advertencia = ref(false)
        const mostrar_relacion= ref([{ver:false,mensaje:''}])
        const opsMasivo = ref(false)
        const cant_elegida = ref(0)
        const filtrado = ref(false)
        const mostrar_validacion = ref(false)
        const carga_exitosa = ref(false)
        const comprobar = store.state.carga_exitosa_ciudad
        const preload = ref(true)
        const comprobar_edi = store.state.edicion_exitosa
        const accion_exitosa = ref(false)
        const paso_elim = ref(false)


        const comprobar_carga = () => {

            // console.log(comprobar)
            if(comprobar==true){
               carga_exitosa.value = true
               let accion = "nuevaciudad"
               store.commit('verificar_carga',accion)
            }

        }
        const comprobar_edicion = () => {

            // console.log(comprobar)
            if(comprobar_edi==true){
               carga_exitosa.value = true
               let accion = "edicionciudad"
               store.commit('verificar_carga',accion)
            }

        }

        const eliminar_adv_masivo = () => {
            let cont = 0
            ciudades.value.forEach(element => {
                if(element.tildado){
                        cont += 1
                }
            })
            // console.log(cont)
            if(cont!=0){
                mostrar_validacion.value = false
                opsMasivo.value =  true
                eliminarCiudades.value = []
                mostrar_advertencia.value = !mostrar_advertencia.value
            }else{
                    mostrar_validacion.value = true
            }
        }


        const verificarHabilitado = async (name) => {
            let habilitado = await queryMe()
            if (habilitado) {
                router.push({name: name})
            } else {
                router.push({name: 'Login'})
            }
        }

        const verificarHabilitadoParam = async (name, params) => {
            let habilitado = await queryMe()
            if (habilitado) {
                router.push({name: name, params: {id: params} })
            } else {
                router.push({name: 'Login'})
            }
        }

        const eliminar_masivamente = () => {
            ciudades.value.forEach(element => {
                if (element.tildado == true) {
                    eliminarCiudades.value.push({id: element.id})

                }
            })
            // console.log(eliminarCiudades.value.length)
            // console.log(eliminarCiudades.value)
            eliminarCiudades.value.forEach(element => {
                eliminar(element.id)
            })
        }
        const eliminar_adv = (ciudad) => {
                    // ops.value.op1=true
                    opsMasivo.value = false
                    mostrar_advertencia.value = !mostrar_advertencia.value
                    ciudadelegida.value.id = ciudad.id
                    ciudadelegida.value.nombre = ciudad.nombre
                    // console.log(ciudadelegida.value.id)
                    // console.log(ciudadelegida.value.nombre)
        }

        const confirmar = () => {
            mostrar_advertencia.value = !mostrar_advertencia.value

            if(opsMasivo.value==true){
                // console.log("entro a la confirmacion para llamar al metodo de eliminar masivamente")
                eliminar_masivamente()
                // console.log(eliminarCiudades.value)
            }else{
                eliminar(ciudadelegida.value.id)
            }
        }
        const cancelar = () => {
            mostrar_advertencia.value = !mostrar_advertencia.value
            // console.log(eliminarCiudades.value)
        }

        watchEffect(() => {
            const client = new GraphQLClient(endpoint) // creamos la consulta para usarlo luego

            client.rawRequest(/* GraphQL */ `
            query($first: Int!){
                municipios(first: $first){
                    paginatorInfo{
                        count
                        currentPage
                        hasMorePages
                        lastPage
                        total
                    }
                    data{
                        id
                        nombre,
                        codigopostal,
                        provincia,
                        pais
                    }
                }
            }`,
            {
                first: 99999
            },
            {
                authorization: `Bearer ${ localStorage.getItem('user_token') }`
            })
            .then( async (data) => {
                // console.log(data)

                await data.data.municipios.data.forEach(element => {
                    Allciudades.value.push({id: element.id ,nombre: element.nombre, codigo_postal: element.codigopostal, provincia: element.provincia, pais: element.pais, tildado: false, activo: false})
                });
                pageCont.value = Math.ceil(Allciudades.value.length/paginaInfo.value.pageSize)

                if (pageCont.value > 1) {
                    paginaInfo.value.next = paginaInfo.value.page + 1
                }

                ciudades_aux.value = Allciudades.value
                showImpresiones(ciudades_aux.value)
                preload.value = false

            }).catch(error => {
                // console.log(error.response);
            })
        })

        const paginate = (array, page_size, page_number) => {
            return array.slice((page_number - 1) * page_size, page_number * page_size)
        }

        const nextPage = () => {
            paginaInfo.value.page++
            if (paginaInfo.value.page >= pageCont.value) {
                paginaInfo.value.next = null
                paginaInfo.value.prev = paginaInfo.value.page - 1
            } else {
                paginaInfo.value.next = paginaInfo.value.page + 1
                paginaInfo.value.prev = paginaInfo.value.page - 1
            }
            showImpresiones(ciudades_aux.value)
        }

        const prevPage = () => {
            paginaInfo.value.page--
            if (paginaInfo.value.page <= 1) {
                paginaInfo.value.prev = null
                paginaInfo.value.next = paginaInfo.value.page + 1
            } else {
                paginaInfo.value.prev = paginaInfo.value.page - 1
                paginaInfo.value.next = paginaInfo.value.page + 1
            }
            showImpresiones(ciudades_aux.value)
        }

        const inicio = () => {
            paginaInfo.value.page = 1
            paginaInfo.value.prev = null
            if (paginaInfo.value.page >= pageCont.value) {
                paginaInfo.value.next = null
            } else {
                paginaInfo.value.next = paginaInfo.value.page + 1
            }
            showImpresiones(ciudades_aux.value)
        }

        const final = () => {
            paginaInfo.value.page = pageCont.value
            paginaInfo.value.next = null
            if (paginaInfo.value.page <= 1) {
                paginaInfo.value.prev = null
            } else {
                paginaInfo.value.prev = paginaInfo.value.page - 1
            }
            showImpresiones(ciudades_aux.value)
        }

        const showImpresiones = (array) => {
            ciudades.value  = paginate(array, paginaInfo.value.pageSize, paginaInfo.value.page)
        }

        watch(mostrar_cantidad, () => {
            // parseInt(mostrar_cantidad.value)
            paginaInfo.value = {page: 1, pageSize: 10, prev: null, next: 0}
            pageCont.value = Math.ceil(ciudades_aux.value.length/paginaInfo.value.pageSize)
            if (pageCont.value > 1) {
                paginaInfo.value.next = paginaInfo.value.page +1
            }
            showImpresiones(ciudades_aux.value)
        })

        watch(buscar, () => {
            filtro_buscador()
        })


        const ordenar = (tipo) => {
            if (tipo == "nombre") {
                ordenarCiudad.value = !ordenarCiudad.value
                clics.value.n1 = true
                clics.value.n2 = false
                clics.value.n3 = false
                clics.value.n4 = false
                // Ascendente
                if (ordenarCiudad.value) {
                    ciudades_aux.value.sort((a,b) => {
                        if (a.nombre.toLowerCase() > b.nombre.toLowerCase()) {
                            return 1
                        }
                        if (a.nombre.toLowerCase() < b.nombre.toLowerCase() ) {
                            return -1
                        }
                        return 0
                    })
                } else {
                    // Descendente
                    ciudades_aux.value.sort((a,b) => {
                        if (a.nombre.toLowerCase() < b.nombre.toLowerCase()) {
                            return 1
                        }
                        if (a.nombre.toLowerCase() > b.nombre.toLowerCase() ) {
                            return -1
                        }
                        return 0
                    })
                }
            } else if (tipo == "codigo_postal") {
                ordenarCodigo.value = !ordenarCodigo.value
                clics.value.n1 = false
                clics.value.n2 = true
                clics.value.n3 = false
                clics.value.n4 = false
                // Ascendente
                if (ordenarCodigo.value) {
                    ciudades_aux.value.sort(function(a,b) {
                        return a.codigo_postal - b.codigo_postal
                    })
                } else {
                    // Descendente
                    ciudades_aux.value.sort(function(a,b) {
                        return b.codigo_postal - a.codigo_postal
                    })
                }
            } else if (tipo == "provincia") {
                ordenarProvincia.value = !ordenarProvincia.value
                clics.value.n1 = false
                clics.value.n2 = false
                clics.value.n3 = true
                clics.value.n4 = false
                // Ascendente
                if (ordenarProvincia.value) {
                    ciudades_aux.value.sort((a,b) => {
                        if (a.provincia.toLowerCase() > b.provincia.toLowerCase()) {
                            return 1
                        }
                        if (a.provincia.toLowerCase() < b.provincia.toLowerCase() ) {
                            return -1
                        }
                        return 0
                    })
                } else {
                    ciudades_aux.value.sort((a,b) => {
                        if (a.provincia.toLowerCase() < b.provincia.toLowerCase()) {
                            return 1
                        }
                        if (a.provincia.toLowerCase() > b.provincia.toLowerCase() ) {
                            return -1
                        }
                        return 0
                    })
                }

            } else if (tipo == 'pais') {
                ordenarPais.value = !ordenarPais.value
                clics.value.n1 = false
                clics.value.n2 = false
                clics.value.n3 = false
                clics.value.n4 = true
                // Ascendente
                if (ordenarPais.value) {
                    ciudades_aux.value.sort((a,b) => {
                        if (a.pais.toLowerCase() > b.pais.toLowerCase()) {
                            return 1
                        }
                        if (a.pais.toLowerCase() < b.pais.toLowerCase() ) {
                            return -1
                        }
                        return 0
                    })
                } else {
                    ciudades_aux.value.sort((a,b) => {
                        if (a.pais.toLowerCase() < b.pais.toLowerCase()) {
                            return 1
                        }
                        if (a.pais.toLowerCase() > b.pais.toLowerCase() ) {
                            return -1
                        }
                        return 0
                    })
                }
            }

            showImpresiones(ciudades_aux.value)
        }

        const filtro_buscador = () => {
            let arreglo_aux = []
            ciudades_aux.value = Allciudades.value
            if (buscar.value != '') {
                filtrado.value = true
                ciudades_aux.value.filter((ciudad)=>{
                    let dato =  ciudad.nombre.toLowerCase().match(buscar.value.toLowerCase())
                        || ciudad.codigo_postal.toLowerCase().match(buscar.value.toLowerCase())
                        || ciudad.provincia.toLowerCase().match(buscar.value.toLowerCase())
                        || ciudad.pais.toLowerCase().match(buscar.value.toLowerCase())

                    if (dato != null) {
                        arreglo_aux.push(ciudad)
                    }

                })
                ciudades_aux.value = arreglo_aux
            } else {
                filtrado.value = false
                ciudades_aux.value = Allciudades.value
            }
            pageCont.value = Math.ceil(ciudades_aux.value.length/paginaInfo.value.pageSize)
            totalElement.value = ciudades_aux.value.length
            paginaInfo.value = {page: 1, pageSize: mostrar_cantidad.value, prev: null, next: 0}
            if (pageCont.value > 1) {
                paginaInfo.value.next = paginaInfo.value.page +1
            }
            showImpresiones(ciudades_aux.value)
        }


        const traerCiudades = () => {
            const client = new GraphQLClient(endpoint) // creamos la consulta para usarlo luego

            watchEffect(() => {

                client.rawRequest(/* GraphQL */ `
                query($page: Int,$first: Int!){
                    municipios(first: $first, page: $page){
                        paginatorInfo{
                            count
                            currentPage
                            hasMorePages
                            lastPage
                            total
                        }
                        data{
                            id
                            nombre,
                            codigopostal,
                            provincia,
                            pais
                        }
                    }
                }`,
                {
                    page: parseInt(route.params.page),
                    first: mostrar_cantidad.value

                },
                {
                    authorization: `Bearer ${ localStorage.getItem('user_token') }`
                })
                .then((data) => {
                    // console.log(data)
                    ciudades.value = []
                    totalElement.value = data.data.municipios.paginatorInfo.total

                    data.data.municipios.data.forEach(element => {
                        ciudades.value.push({id: element.id ,nombre: element.nombre, codigo_postal: element.codigopostal, provincia: element.provincia, pais: element.pais, tildado: false, activo: false})

                    });

                }).catch(error => {
                    // console.log(error.response);
                })
            })

        }

        // const eliminar = (ciudad_id) => {
        //     // let habilitado =  queryMe()
        //     // if (habilitado) {
        //         eliminando(ciudad_id)
        //     // } else {
        //     //     router.push({name: 'Login'})
        //     // }
        // }

        const eliminar = async (ciudad_id) => {
            let habilitado = await queryMe()
            // console.log(habilitado)
            if (habilitado) {
                eliminando(ciudad_id)
            }
            else {
                router.push({name: 'Login'})
            }
        }


        const eliminando =  (ciudad_id) => {
            const client = new GraphQLClient(endpoint)
            // console.log(ciudad_id)
            client.rawRequest(/* GraphQL */ `
            mutation($id: ID!){
                eliminaMunicipio(id: $id){
                    id,
                    nombre
                }
            }`,
            {
                id: ciudad_id
            },
            {
                authorization: `Bearer ${ localStorage.getItem('user_token') }`
            })
            .then((data) => {
                // console.log(data)
                accion_exitosa.value = true
                paso_elim.value = true

                // Busca el elemento y lo elimina del arreglo para que se vea dinamico para el cliente
                let cont = 0
                ciudades_aux.value.forEach(element => {
                    if (element.id == data.data.eliminaMunicipio.id) {
                        ciudades_aux.value.splice(cont,1)
                    }
                    cont = cont +1
                })
                // console.log(ciudades_aux.value)
                pageCont.value = Math.ceil(ciudades_aux.value.length/paginaInfo.value.pageSize)
                totalElement.value = ciudades_aux.value.length
                paginaInfo.value = {page: 1, pageSize: mostrar_cantidad.value, prev: null, next: 0}
                if (pageCont.value > 1) {
                    paginaInfo.value.next = paginaInfo.value.page +1
                }
                showImpresiones(ciudades_aux.value)
            })
            .catch(error => {
                let mensaje = error.message
                // console.log(error.response)
                accion_exitosa.value = false
                paso_elim.value = false

                if(mensaje.includes('No puede eliminir la ciudad. Tiene Anunciantes relacionados')){
                    mostrar_relacion.value.ver = !mostrar_relacion.value.ver
                    mostrar_relacion.value.mensaje = 'No puede eliminir la ciudad. Tiene Anunciantes relacionados'

                }
                if(mensaje.includes('No se puede eliminar la ciudad. Posee Puntos Wifi relacionados')){
                    mostrar_relacion.value.ver = !mostrar_relacion.value.ver
                    mostrar_relacion.value.mensaje= 'No se puede eliminar la ciudad. Posee Puntos Wifi relacionados'

                }
            })
        }


        const modal = (item) =>{
            item.activo = !item.activo
        }

        const tildar = (ciudad) => {
            ciudad.tildado = !ciudad.tildado
        }


        const marcar_todo = () => {
            todo.value = !todo.value
            if(todo.value){
                ciudades_aux.value.forEach(element => {
                    element.tildado = true
                })
            } else{
                ciudades_aux.value.forEach(element => {
                    element.tildado = false
                });
            }

            showImpresiones(ciudades_aux.value)
        }

        const filtro = () => {
            if (!filtrado.value) {
                limpiarFiltro()
            }
            filtrar.value = true
        }

        const limpiarFiltro = () => {
            datos_filtro.value = {nombre: '', codigopostal: '', provincia: '', pais: ''},
            tipo_filtro.value = {nombre: 'contiene', codigopostal: 'es', provincia: 'contiene', pais: 'contiene'}
            buscar.value = ''
        }

        const filtro_avanzado = () => {
            let aux = [] // Sera usada para guardar los datos que coincidan y luego pasarlo al objeto ciudad
            let ciud_aux = Allciudades.value

            if (datos_filtro.value.nombre == '' && datos_filtro.value.codigopostal == '' && datos_filtro.value.provincia == '' && datos_filtro.value.pais == ''){
                filtrado.value = false
            } else {
                filtrado.value = true
            }

            if (buscar.value != '') {
                filtro_buscador()
            }

            ciudades.value = ciud_aux

            //filtrar_campo_numeral(dato, tipo, cod, arreglo, aux)
            if (datos_filtro.value.codigopostal != '') {
                ciudades.value = filtrar_campo_numeral(datos_filtro.value.codigopostal, tipo_filtro.value.codigopostal, "codigo_postal", ciudades.value, aux, "ciudades")
            }
            aux = []

            if (datos_filtro.value.nombre != '') {
                ciudades.value = filtrar_campo_texto(datos_filtro.value.nombre, tipo_filtro.value.nombre, "nombre", ciudades.value, aux, "ciudades")
            }
            aux = []

            if (datos_filtro.value.provincia != '') {
                ciudades.value = filtrar_campo_texto(datos_filtro.value.provincia, tipo_filtro.value.provincia, "provincia", ciudades.value, aux, "ciudades")
            }
            aux = []

            if (datos_filtro.value.pais != '') {
                ciudades.value = filtrar_campo_texto(datos_filtro.value.pais, tipo_filtro.value.pais, "pais", ciudades.value, aux, "ciudades")
            }

            pageCont.value = Math.ceil(ciudades.value.length/paginaInfo.value.pageSize)
            totalElement.value = ciudades.value.length
            paginaInfo.value = {page: 1, pageSize: mostrar_cantidad.value, prev: null, next: 0}
            if (pageCont.value > 1) {
                paginaInfo.value.next = paginaInfo.value.page +1
            }
            ciudades_aux.value = ciudades.value


            showImpresiones(ciudades_aux.value)

            filtrar.value = false
        }

        document.addEventListener('click', function(e) {
            let clic = e.target.getAttribute("id")

            if (clic != "mas-opciones")  {
                mas_opciones.value = false
                if (clic == 'modal-background') {
                    filtrar.value = false
                }
            }
        }, false)


        return{

            todo,
            mostrar_cantidad,
            isMobile,
            ciudades,
            ciudades_aux,
            datos_filtro,
            tipo_filtro,
            filtrar,
            buscar,
            info,
            route,
            ordenarCodigo,
            ordenarProvincia,
            ordenarPais,
            clics,

            traerCiudades,
            eliminar,
            modal,
            tildar,
            marcar_todo,
            filtro,
            limpiarFiltro,
            filtro_avanzado,
            ordenarCiudad,
            eliminar_masivamente,

            nextPage,
            prevPage,
            inicio,
            final,
            paginaInfo,
            pageCont,
            totalElement,
            ordenar,
            mas_opciones,
            verificarHabilitado,
            verificarHabilitadoParam,
            mostrar_advertencia,
            eliminar_adv,
            confirmar,
            cancelar,
            ciudadelegida,
            opsMasivo ,
            eliminar_adv_masivo,
            eliminarCiudades,
            cant_elegida,
            mostrar_relacion,
            Allciudades,
            filtrado,
            mostrar_validacion ,
            carga_exitosa,
            comprobar,
            comprobar_carga,
            preload,
            comprobar_edicion,
            comprobar_edi,
            accion_exitosa,
            paso_elim,

        }


    }


}
</script>

<style scoped>

.boton-tamaño{
    width: 67%;
}

@media screen and (max-width: 700px) {
    .boton-tamaño{
    width: 66%;
  }
}

.seleccionado{
    background-color: #01AC97;
}
.seleccionado:hover{
    background-color: #008575;
}

.deseleccionado:hover{
    background-color: #EDEDED;
}

.fondo-fila{

    background-color: rgb(223, 219, 219);

}
</style>