<template>
    <div class="container">
        <div class="column">
            <div class="columns">
                <div v-show="!isMobile" class="columns   pt-3 ">
                    <div class="column  mr-0 pr-0 ">
                        <button class="button is-static has-text-black" >Mostrar</button>
                    </div>
                    <div class="column  pl-0 control" v-tooltip="'Filas'">
                        <div class="select " >
                            <select class="options" v-model="mostrar_cantidad">
                                <option value="50" >50</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                                <option value="500">500</option>
                            </select>
                        </div>
                    </div>
                 </div>
                <div v-show="isMobile" class="columns has-text-right is-mobile pt-3">
                    <div class="column  mr-0 pr-0 has-text-right">
                        <button class="button is-static has-text-black">Mostrar</button>
                    </div>
                    <div class="column  pl-0  has-text-left control" >
                        <div class="select ">
                            <select class="options" v-model="mostrar_cantidad">
                                <option value="50" >50</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                                <option value="500">500</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="column is-5 ">
                    <div class="buttons ">

                        <button class="button has-background-info has-text-white" @click="filtro">Filtro</button>

                        <div>
                            <div class="modal" :class="{'is-active': filtrar}">
                                <div id="modal-background" class="modal-background " style="background-color: rgb(197, 197, 197, 0.1)"></div>
                                <div class="modal-content-width">
                                    <div class="container has-text-centered has-background-white p-3" id="modal">

                                        <div class="columns mb-0">
                                            <div class="column is-9 pb-0" :class="{'ml-5':!isMobile}">
                                                <h1 class="is-size-3 has-text-black has-text-weight-bold pt-3" :class="{'ml-5':!isMobile}">Filtrar tabla</h1>
                                            </div>
                                            <div class="column" :class="{'mt-3':!isMobile}">
                                                <button class="button verde-puwic mb-0 has-text-white has-text-weight-bold" @click="limpiarFiltro">Limpiar</button>
                                            </div>
                                        </div>


                                        <div :class="{'modal-scrolleable' : isMobile}">
                                            <!-- Anunciante -->
                                            <div class="field columns mt-2" :class="{'mb-0' : isMobile}">
                                                <div class="column is-one-fifth ml-4 mt-2 pt-0" style="min-width: 50px">
                                                    <label class="label" :class="{'has-text-right': !isMobile}">Anunciante</label>
                                                </div>
                                                <div class="columns has-text-centered ml-0 mr-0 w-100 is-align-self-center">
                                                    <div class="control column is-flex-grow-0" :class="{'py-1': isMobile}">
                                                        <div class="select" style="width: 139px">
                                                            <select class="options" v-model="tipo_filtro.anunciante" style="width: 139px">
                                                                <option value="contiene">contiene</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="control column" :class="{'pt-1': isMobile}">
                                                        <input class="input" v-model="datos_filtro.anunciante" name="anunciante" type="text">
                                                    </div>

                                                </div>
                                            </div>

                                            <!-- Anuncio -->
                                            <div class="field columns" :class="{'mb-0 pt-1' : isMobile, 'mt-4':!isMobile}">
                                                <div class="column is-one-fifth ml-4 pt-2">
                                                    <label class="label" :class="{'has-text-right': !isMobile}" >Anuncio</label>
                                                </div>
                                                <div class="columns has-text-centered ml-0 mr-0 w-100 is-align-self-center">
                                                    <div class="control column is-flex-grow-0" :class="{'py-1' : isMobile}">
                                                        <div class="select" >
                                                            <select class="options" v-model="tipo_filtro.anuncio">
                                                                <option value="contiene">contiene</option>
                                                                <option value="no contiene">no contiene</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="control column" :class="{'pt-1' : isMobile}">
                                                        <input class="input" v-model="datos_filtro.anuncio" name="anuncio" type="text">
                                                    </div>

                                                </div>
                                            </div>

                                            <!-- Motivo -->
                                            <div class="field columns mb-0" :class="{'pt-1' : isMobile, 'mt-4':!isMobile}">
                                                <div class="column is-one-fifth ml-4 pt-2">
                                                    <label class="label" :class="{'has-text-right': !isMobile}" >Motivo</label>
                                                </div>
                                                <div class="columns has-text-centered ml-0 mr-0 w-100 is-align-self-center">
                                                    <div class="control column is-flex-grow-0" :class="{'py-1' : isMobile}">
                                                        <div class="select" style="width: 8.7rem">
                                                            <select class="options" v-model="tipo_filtro.motivo" style="width: 8.7rem">
                                                                <option value="es">es</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="control column" :class="{'pt-1' : isMobile}">
                                                        <!-- <input class="input" v-model="datos_filtro.punto_wifi" name="punto_wifi" type="text"> -->
                                                        <div class="select w-100">
                                                            <select class="options w-100" v-model="datos_filtro.motivo">
                                                                <option value="ninguno"></option>
                                                                <option value="Envio manual">Envio manual</option>
                                                                <option value="Envio automatico">Envio automatico</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <!-- Fecha Desde y Hasta -->
                                            <div class="field columns mt-0 mb-0" :class="{'mb-0 mt-2': isMobile, 'mt-2':!isMobile}">
                                                <div class="column is-one-fifth ml-4 pt-2 is-align-self-center" style="min-width: 50px">
                                                    <label class="label" :class="{'has-text-right': !isMobile}">Fecha de envio</label>
                                                </div>
                                                <div class="column">
                                                    <div class="columns">
                                                        <div class="column is-flex-grow-0" >
                                                            <label class="label mt-2" style="width: 139px; margin: auto">Desde</label>
                                                        </div>

                                                        <div class="control column ">

                                                            <input type="date" class="input input2" v-model="datos_filtro.desde" />

                                                        </div>
                                                    </div>

                                                    <div class="columns">
                                                        <div class="column is-flex-grow-0" >
                                                            <label class="label mt-2" style="width: 139px; margin: auto">Hasta</label>
                                                        </div>

                                                        <div class="control column ">

                                                            <input type="date" class="input input2" v-model="datos_filtro.hasta" />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Botones -->
                                            <div class="columns">
                                                <div class="column">
                                                    <div class="buttons">
                                                        <div class="column is-4 px-1">
                                                            <button class="button has-background-danger is-outlined btn has-text-white has-text-weight-bold" style="width: 100%" @click="filtrar = false">Cancelar</button>
                                                        </div>
                                                        <div class="column pl-0">
                                                            <button class="button verde-puwic is-outlined btn has-text-white has-text-weight-bold" :class="{'ml-2':!isMobile}" style="width: 100%" @click="filtro_avanzado">Filtro</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <router-link :to="{name: 'NuevaCiudad'}" class="button has-background-link has-text-white">Nueva ciudad</router-link> -->

                    </div>
                </div>
            </div>
        </div>

        <button class="total-element">Total: {{total}}</button>
        <button v-show="filtrado" class="total-element mx-0 pointer" @click="filtro">Total filtrado: {{info.total}}</button>

        <div class="column table-container">
            <table class="table has-background-white-ter " style="width: 100%" >
                <thead>
                    <tr>
                    <th>
                       <!-- <div v-show="todo">
                            <button class="button is-small is-light"  @click="marcar_todo">
                                    <span class="icon-text">
                                        <span class="icon is-size-6">
                                            <i class="far fa-check-square"></i>
                                        </span>
                                    </span>
                            </button>
                        </div>
                        <div v-show="!todo">
                            <button class="button is-small is-light"  @click="marcar_todo">
                                <span class="icon-text">
                                    <span class="icon is-size-6">
                                        <i class="far fa-square"></i>
                                    </span>
                                </span>
                            </button>
                        </div> -->
                    </th>
                    <th>
                        Anunciante
                        <!-- <button @click="ordenar('nombre')" class="button  has-text-weight-medium has-text-black is-light px-0 py-0 "> -->
                            <!-- Nombre del anunciante -->
                            <!-- <div  v-show="clics.n1!=false">
                                <span  v-show="ordenarCiudad!=false">
                                    <i class="fas fa-arrow-up"></i>
                                </span>
                                <span v-show="ordenarCiudad==false">
                                    <i class="fas fa-arrow-down"></i>
                                </span>
                            </div> -->
                        <!-- </button> -->
                    </th>
                    <th>
                        Anuncio
                        <!-- <button @click="ordenar('codigo_postal')" class="button  has-text-weight-medium has-text-black is-light px-0 py-0"> -->
                            <!-- <div  v-show="clics.n2!=false">
                                <span  v-show="ordenarCodigo!=false">
                                    <i class="fas fa-arrow-up"></i>
                                </span>
                                <span v-show="ordenarCodigo==false">
                                    <i class="fas fa-arrow-down"></i>
                                </span>
                            </div> -->
                        <!-- </button> -->
                    </th>
                    <th>
                        Motivo
                        <!-- <button @click="ordenar('provincia')" class="button  has-text-weight-medium has-text-black is-light px-0 py-0">
                            Provincia
                            <div  v-show="clics.n3!=false">
                                <span  v-show="ordenarProvincia!=false">
                                    <i class="fas fa-arrow-up"></i>
                                </span>
                                <span v-show="ordenarProvincia==false">
                                    <i class="fas fa-arrow-down"></i>
                                </span>
                            </div>
                        </button> -->
                    </th>
                    <th>
                        Fecha de envio
                        <!-- <button @click="ordenar('pais')" class="button  has-text-weight-medium has-text-black is-light px-0 py-0">
                            Pais
                            <div  v-show="clics.n4!=false">
                                <span  v-show="ordenarPais!=false">
                                    <i class="fas fa-arrow-up"></i>
                                </span>
                                <span v-show="ordenarPais==false">
                                    <i class="fas fa-arrow-down"></i>
                                </span>
                            </div>
                        </button> -->
                    </th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(correo,index) in correos" :index="index" :key="correo.id" :class="{'fondo-fila':index%2==0 && !correo.tildado,'seleccionado': correo.tildado, 'deseleccionado': !correo.tildado}">

                         <td>
                            <!-- <input v-if="!ciudad.tildado" @click="tildar(ciudad)" class="ml-2"  type="checkbox">
                            <input v-else @click="tildar(ciudad)"  class="ml-2" checked type="checkbox"> -->
                        </td>
                        <td >{{correo.nombre_anunciante}}</td>
                        <td >{{correo.nombre_anuncio}}</td>
                        <td >{{correo.nombre_motivo}}</td>
                        <td >{{correo.fecha_envio}}</td>

                        <!-- <div>
                            <div class="modal" :class="{'is-active': ciudad.activo}" @click="ciudad.activo = false">
                                <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.1)"></div>
                                <div class="modal-content-width" style="border:1px ridge grey;">
                                    <div class="container has-text-centered has-background-white" style="width: 200px" id="modal">
                                        <div class="column p-0 py-1 pt-4 px-4">
                                             <router-link :to="{name: 'EditarCiudad', params: {id: ciudad.id}}" class="button verde-puwic is-outlined btn has-text-white has-text-weight-bold" style="width: 100%">Editar</router-link> -->
                                            <!-- <a @click="verificarHabilitadoParam('EditarCiudad', ciudad.id)" class="button verde-puwic is-outlined btn has-text-white has-text-weight-bold" style="width: 100%">Editar</a>
                                        </div>
                                        <div class="column p-0 pb-4 px-4">
                                            <button class="button has-background-danger is-outlined btn has-text-white has-text-weight-bold" style="width: 100%" @click="eliminar_adv(ciudad)">Eliminar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>  -->

                    </tr>
                </tbody>
            </table>

            <div v-show="preload" class="loader-wrapper is-active-loading">
                <div class="loader is-loading "></div>
            </div>

            <div v-if="!preload && correos.length == 0">
                <h1 class="has-text-centered is-size-4 has-text-grey">No hay correos</h1>
            </div>
        </div>
        <nav v-show="info.total > mostrar_cantidad" class="pagination is-right" role="navigation" aria-label="pagination">
            <router-link v-if="info.page > 1" class="pagination-previous" :to="prevComp">Anterior</router-link>
            <router-link v-else class="pagination-previous" disabled to="#">Anterior</router-link>
            <router-link v-if="info.page < info.pages" class="pagination-next" :to="nextComp">Siguiente</router-link>
            <router-link v-else class="pagination-next" disabled to="#">Next</router-link>
            <ul class="pagination-list" style="justify-content: center">
                <li v-show="info.prev > 1"><router-link class="pagination-link" :to="primero">1</router-link></li>
                <li v-show="info.prev > 2"><span class="pagination-ellipsis">&hellip;</span></li>
                <li v-show="info.prev != null"><router-link class="pagination-link" :to="prevComp">{{info.prev}}</router-link></li>
                <li><router-link class="pagination-link is-current" to="#">{{info.page}}</router-link></li>
                <li v-show="info.next != null"><router-link class="pagination-link" :to="nextComp">{{info.next}}</router-link></li>
                <li v-show="info.next < info.pages - 1 && info.next != null"><span class="pagination-ellipsis">&hellip;</span></li>
                <li v-show="info.next != null && (info.next < info.pages)"><router-link class="pagination-link" :to="ultimo">{{info.pages}}</router-link></li>
            </ul>
        </nav>
    </div>

</template>

<script>

import store from '@/store';
import { GraphQLClient, gql } from 'graphql-request'
import {computed, inject, ref, watch, watchEffect} from 'vue'
import { useRoute, useRouter } from "vue-router";
import {queryMe} from '@/composables/queryMe.js'



export default {

    created(){
        this.traerCorreos()
        // this.correos_aux = this.correos
    },

    setup(){

        const todo = ref(false)
        const mostrar_cantidad = ref(50)
        const isMobile = inject('isMobile')

        const correos = ref([])
        const correos_aux = ref([])
        const datos_filtro = ref({anunciante: '', anuncio: '', motivo: '', desde: '', hasta: ''})
        const tipo_filtro = ref({anunciante: 'contiene', anuncio: 'contiene', motivo: 'es', desde: 'es', hasta: 'es'})
        const filtrar =  ref(false)
        const info = ref({count: 0, pages:0, next:0, prev: 0, page: 0, total: 0})
        const route = useRoute()
        const router = useRouter()
        const endpoint = store.state.url_backend

        const Allcorreos = ref([])
        const paginaInfo = ref({page: 1, pageSize: parseInt(mostrar_cantidad.value), prev: null, next: 0})
        const pageCont = ref(0) // capaz tenga que agregarlo en la consulta de datos
        const totalElement = ref(0)
        const correoelegida = ref([{id:null,nombre:''}])
        const mostrar_advertencia = ref(false)
        const mostrar_relacion= ref([{ver:false,mensaje:''}])
        const opsMasivo = ref(false)
        const cant_elegida = ref(0)
        const dato = ref({nombre_anunciante: null, nombre_anuncio: null, nombre_motivo: null,
        fecha_desde: '', fecha_hasta: '', not_nombre_anuncio: null})
        const filtrado = ref(false)
        const total = ref(0)
        const preload = ref(true)

        const prevComp = computed(() => {
            return{
                name : route.name,
                params: {page: info.value.prev || route.params.page}
            }
        })
        const nextComp = computed(() => {
            return{
                name: route.name,
                params: {page: info.value.next || route.params.page}
            }
        })
        const ultimo = computed(() => {
            return{
                name: route.name,
                params: {page: info.value.pages}
            }
        })
        const primero = computed(() =>{
            return{
                name: route.name,
                params: {page: 1}
            }
        })


        const eliminar_adv_masivo = () => {
                    opsMasivo.value =  true

                    mostrar_advertencia.value = !mostrar_advertencia.value
                    // cant_elegida.value = 0
                    // ciudades_aux.value.forEach(element => {

                    //     if (element.tildado == true) {
                    //         cant_elegida.value +=1
                    //         eliminarCiudades.value.push({id: element.id})
                    //     }
                    // })

                // console.log(eliminarCiudades.value.length)
        }


        const verificarHabilitado = async (name) => {
            let habilitado = await queryMe()
            if (habilitado) {
                router.push({name: name})
            } else {
                router.push({name: 'Login'})
            }
        }

        const verificarHabilitadoParam = async (name, params) => {
            let habilitado = await queryMe()
            if (habilitado) {
                router.push({name: name, params: {id: params} })
            } else {
                router.push({name: 'Login'})
            }
        }

        const eliminar_masivamente = async () => {
            // let habilitado = await queryMe()



            correos_aux.value.forEach(element => {
                if (element.tildado == true) {
                    // eliminarCorreos.value.push({id: element.id})

                }
            })
            // console.log(eliminarCiudades.value)

            // eliminarCorreos.value.forEach(element => {
            //     // eliminar(element.id)
            // })

            // if (habilitado) {

            // } else {
            //     router.push({name: 'Login'})
            // }

        }

        const eliminar_adv = (correo) => {
                    // ops.value.op1=true
                    opsMasivo.value = false
                    mostrar_advertencia.value = !mostrar_advertencia.value
                    correoelegida.value.id = correo.id
                    correoelegida.value.nombre = correo.nombre
                    // console.log(ciudadelegida.value.id)
                    // console.log(ciudadelegida.value.nombre)
        }

        const confirmar = () => {
            mostrar_advertencia.value = !mostrar_advertencia.value

            if(opsMasivo.value==true){
                // eliminar_masivamente()
                // console.log(eliminarCiudades.value)
            }

            // eliminar(correoelegida.value.id)
        }

        const cancelar = () => {
            mostrar_advertencia.value = !mostrar_advertencia.value
            // console.log(eliminarCiudades.value)
        }



        const showImpresiones = (array) => {
            // correos.value  = paginate(array, paginaInfo.value.pageSize, paginaInfo.value.page)
        }

        watch(mostrar_cantidad, () => {
            traerCorreos()
        })

        const filtro_avanzado = () => {
            dato.value.nombre_anunciante = null
            dato.value.nombre_anuncio = null
            dato.value.not_nombre_anuncio = null
            dato.value.nombre_motivo = null

            if (datos_filtro.value.anunciante != '') {
                dato.value.nombre_anunciante = datos_filtro.value.anunciante
            }

            if (datos_filtro.value.anuncio != '') {
                if (tipo_filtro.value.anuncio == 'contiene') {
                    dato.value.nombre_anuncio = datos_filtro.value.anuncio
                } else {
                    dato.value.not_nombre_anuncio = datos_filtro.value.anuncio
                }
            }

            if (datos_filtro.value.motivo != '') {
                if (datos_filtro.value.motivo != 'ninguno') {
                    dato.value.nombre_motivo = datos_filtro.value.motivo
                }
            }

            traerCorreos()
        }


        const traerCorreos = () => {
            const client = new GraphQLClient(endpoint) // creamos la consulta para usarlo luego

            if (datos_filtro.value.anunciante == '' && datos_filtro.value.anuncio == '' && datos_filtro.value.motivo == ''
            && datos_filtro.value.desde == '' && datos_filtro.value.hasta == '') {
                filtrado.value = false
            } else {
                filtrado.value = true
            }

            let desde = "1800-01-01 00:00:00"
            let hasta = "3000-12-31 00:00:00"

            if (datos_filtro.value.desde != '') {
                dato.value.fecha_desde = datos_filtro.value.desde + ' 00:00:00'
            } else {
                dato.value.fecha_desde = desde
            }

            if (datos_filtro.value.hasta != '') {
                dato.value.fecha_hasta = datos_filtro.value.hasta + ' 23:59:59'
            } else {
                dato.value.fecha_hasta = hasta
            }

            if (filtrar.value) {
                filtrar.value = false
                router.push({name: 'TableroEmails', params: {page:1}})
            }

            total_correo()

            watchEffect(() => {

                let arreglo = []
                client.rawRequest(/* GraphQL */ `
                query($page: Int,$first: Int!,$nombre_anunciante: String,$nombre_motivo:String,$nombre_anuncio: String, $not_nombre_anuncio: String, $fecha_desde: DateTime, $fecha_hasta: DateTime){
                    emailsEnviadosFilter(first: $first, page: $page,input:{
                        nombre_anunciante: $nombre_anunciante,
                        nombre_anuncio: $nombre_anuncio,
                        not_nombre_anuncio: $not_nombre_anuncio,
                        nombre_motivo: $nombre_motivo,
                        fecha_desde: $fecha_desde,
                        fecha_hasta: $fecha_hasta,
                    }){
                        paginatorInfo{
                            count
                            currentPage
                            hasMorePages
                            lastPage
                            total
                        }
                        data{
                            id
                            anunciante_nombre
                            anuncio_nombre
                            motivo
                            fecha_envio
                        }
                    }
                }`,
                {

                    page: parseInt(route.params.page),
                    first: mostrar_cantidad.value,
                    nombre_anunciante: dato.value.nombre_anunciante,
                    nombre_anuncio: dato.value.nombre_anuncio,
                    nombre_motivo: dato.value.nombre_motivo,
                    fecha_desde: dato.value.fecha_desde,
                    fecha_hasta: dato.value.fecha_hasta,
                    not_nombre_anuncio: dato.value.not_nombre_anuncio

                },
                {
                    authorization: `Bearer ${ localStorage.getItem('user_token') }`
                })
                .then((data) => {
                    // console.log(data)
                    totalElement.value = data.data.emailsEnviadosFilter.paginatorInfo.total

                    data.data.emailsEnviadosFilter.data.forEach(element => {
                        arreglo.push({nombre_anunciante: element.anunciante_nombre ,nombre_anuncio: element.anuncio_nombre, nombre_motivo: element.motivo, fecha_envio: element.fecha_envio, tildado: false, activo: false})
                    });
                    correos.value = arreglo

                    let pageInfo = data.data.emailsEnviadosFilter.paginatorInfo
                    store.commit("paginacion", {pageInfo: pageInfo, info: info.value})
                    preload.value = false

                    // console.log(pageInfo)



                }).catch(error => {
                    // console.log(error.response);
                })
            })

        }

        const total_correo = () => {
            const client = new GraphQLClient(endpoint)
            client.rawRequest(/* GraphQL */ `
            query($first: Int!){
                emailsEnviadosFilter(first: $first, input: {
                }) {
                    paginatorInfo {
                        total
                    }
                    data {
                        id
                    }
                }
            }`,
            {
                first: 9999999
            },
            {
                authorization: `Bearer ${ localStorage.getItem('user_token') }`
            })
            .then((data) => {
                total.value = data.data.emailsEnviadosFilter.paginatorInfo.total
            })
            .catch(error => {
                // console.log(error.response)
            })
        }


        const modal = (item) =>{
            item.activo = !item.activo
        }

        const tildar = (ciudad) => {
            ciudad.tildado = !ciudad.tildado
        }


        const marcar_todo = () => {
            todo.value = !todo.value
            if(todo.value){
                correos_aux.value.forEach(element => {
                    element.tildado = true
                })
            } else{
                correos_aux.value.forEach(element => {
                    element.tildado = false
                });
            }

            showImpresiones(correos_aux.value)
        }

        const filtro = () => {
            if (!filtrado.value) {
                limpiarFiltro()
            }
            filtrar.value = true
        }

        const limpiarFiltro = () => {
            datos_filtro.value = {anunciante: '', anuncio: '', motivo: '', desde: '', hasta: ''}
            tipo_filtro.value = {anunciante: 'contiene', anuncio: 'contiene', motivo: 'es', desde: 'es', hasta: 'es'}
        }

        document.addEventListener('click', function(e) {
            let clic = e.target.getAttribute("id")

            if (clic == 'modal-background') {
                filtrar.value = false
            }

        }, false)


        return{

            todo,
            mostrar_cantidad,
            isMobile,
            correos,
            correos_aux,
            datos_filtro,
            tipo_filtro,
            filtrar,
            info,
            route,

            traerCorreos,
            modal,
            tildar,
            marcar_todo,
            filtro,
            limpiarFiltro,
            filtro_avanzado,
            paginaInfo,
            pageCont,
            totalElement,
            total,
            // ordenar,
            verificarHabilitado,
            verificarHabilitadoParam,
            mostrar_advertencia,
            eliminar_adv,
            confirmar,
            cancelar,
            correoelegida,
            opsMasivo ,
            eliminar_adv_masivo,
            // eliminarCorreos,
            cant_elegida,
            mostrar_relacion,
            Allcorreos ,

            prevComp,
            nextComp,
            ultimo,
            primero,
            total_correo,
            filtrado,
            preload

        }


    }


}
</script>

<style scoped>

.boton-tamaño{
    width: 67%;
}

@media screen and (max-width: 700px) {
    .boton-tamaño{
    width: 66%;
  }
}

.seleccionado{
    background-color: #01AC97;
}
.seleccionado:hover{
    background-color: #008575;
}

.deseleccionado:hover{
    background-color: #EDEDED;
}

.fondo-fila{

    background-color: rgb(223, 219, 219);

}
</style>