<template>
    <div class="columns mt-3 ml-2 is-multiline is-mobile">
        <div class="column boton-mobil">
            <!-- <router-link class="px-2" :to="{name: 'ConfiguracionDeMapa'}">
                <button class="button verde-puwic is-outlined p-6 btn" :class="{'has-text-white' : isMobile}">Configuración de integración mapa de Google</button>
            </router-link> -->

            <router-link class="px-2" :to="{name: 'IntegracionEnvioCorreo'}">
                <button class="button is-outlined p-6 btn" :class="{'has-text-white' : isMobile}">Integración con envio de emails</button>
            </router-link>
        </div>
    </div>
      <!-- Mensaje de que se cargo con exito -->
    <div>
        <div class="modal" :class="{'is-active': carga_exitosa}">
            <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.0)"></div>
            <div class="modal-content-width has-text-black" style="border:1px ridge grey;" :class="{'modal-puntowifi-escritorio' : !isMobile, 'modal-puntowifi-mobil' : isMobile}">
                <div class="container has-text-centered has-background-white" :class="{'p-2':isMobile, 'p-5':!isMobile}" id="modal">
                    <!-- <h1 class="is-size-3 has-text-weight-semibold" :class="{'is-size-4':isMobile}">No se puede borrar el anuncio</h1> -->
                    <p v-show="comprobar==true" class="has-text-centered has-text-success">Se cargo con exito la configuracion de email.</p>
                    <p v-show="comprobar_edi==true" class="has-text-centered has-text-success">Se edito con exito la configuracion de email.</p>
                    <div class="columns mt-2">
                        <div class="column">
                            <button class="button w-100 verde-puwic is-outline btn  has-text-weight-blod" @click="carga_exitosa = false">Esta bien</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>

<script>

import { inject, ref } from '@vue/runtime-core'
import store from '@/store';

export default {
    created(){
        this.comprobar_carga()
        this.comprobar_edicion()
    },

    setup(){
        const isMobile = inject('isMobile')
        const carga_exitosa = ref(false)
        const comprobar = store.state.carga_exitosa_ciudad
        const comprobar_edi = store.state.edicion_exitosa

        const comprobar_carga = () => {

            // console.log(comprobar)
            if(comprobar==true){
               carga_exitosa.value = true
               let accion = "nuevaintegracion"
               store.commit('verificar_carga',accion)
            }

        }
        const comprobar_edicion = () => {

            // console.log(comprobar)
            if(comprobar_edi==true){
               carga_exitosa.value = true
               let accion = "edicionintegracion"
               store.commit('verificar_carga',accion)
            }

        }

        return{
            isMobile,
            carga_exitosa,
            comprobar,
            comprobar_edicion,
            comprobar_carga,
            comprobar_edi,
        }

    }

}
</script>

<style scoped lang="scss">

@media screen and (max-width: 770px) {
    .btn{

    background-color: #008575;
  }
}
@media screen and (min-width: 769px) {
    .btn{
    background-color: transparent;
  }
}

.btn{
    width: 230px;
    white-space: normal;

    color: #008575;
    border-color: #008575;
}

.btn:hover{
    background-color: #008575;
    color: white;
}

@media screen and (max-width: 770px) {
    .boton-mobil{
    text-align: center;
  }
    .btn{
        margin-top: 8px;
        margin-bottom: 8px;
    }
}

.button{
    border-style: solid;
    border-width: 3px;
}

</style>

